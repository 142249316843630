@font-face {
    font-family: "Clash Display";
    src: url("../assets/ClashDisplay-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 62.5%;
    font-weight: normal;
    font-family: "Barlow", sans-serif;
}

body {
    margin: 0;
    color: #fff;
    font-size: 1.6rem;
    font-weight: normal;
    background-color: #161616;
    font-family: "Barlow", sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
    font-weight: normal;
    font-family: "Barlow", sans-serif;
}

.app-container {
    display: flex;
    max-width: 128rem;
    align-items: flex-start;
    margin: 4rem auto 5.2rem auto;
}

.app-sidebar {
    top: 4rem;
    height: 84vh;
}

.leaderboard-table {
    margin-top: 6rem;
    padding: 3rem 1rem;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(180deg, #fff 25%, #f1f1f1);
    .table-wrapper {
        height: 42rem;
        overflow-x: auto;
        table {
            width: 100%;
            white-space: nowrap;
            td {
                color: #161616;
                font-size: 1.6rem;
                padding: 1.8rem 0.8rem;
            }
            tbody tr {
                &:hover {
                    background-color: #fffc00;
                }
            }
            th {
                font-size: 1.8rem;
                text-align: left;
                font-weight: normal;
                padding: 3rem 0.8rem;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .leaderboard-table {
        .table-wrapper {
            width: 74vw;
            .address-cell {
                width: 200px;
            }
        }
    }
}

.stake-modal {
    > div {
        > div {
            bottom: 0 !important;
            height: 100% !important;
            align-items: normal !important;
            > div {
                &:first-child {
                    padding: 2rem;
                    height: auto !important;
                    overflow: auto !important;
                    background-color: rgb(51, 51, 51) !important;
                }
                .close-btn {
                    top: 3rem;
                    right: 3rem;
                    position: absolute;
                }
            }
        }
    }
}

.project-item {
    position: relative;
    .wallet-btn {
        margin: 0 auto;
        display: table;
        font-size: 1.6rem;
        padding: 1.6rem 3.2rem;
    }
    .social-link {
        top: 1rem;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
        position: absolute;
        > circle {
            fill: transparent;
        }
        &.twitter {
            left: 1rem;
        }
        &.discord {
            right: 1rem;
        }
    }
}

.flex-text {
    span.dot-flashing + span {
        margin-left: 1.6rem;
    }
}

.mobile {
    display: none;
}

.secondary-font {
    font-family: "Clash Display", sans-serif;
}

.primary-btn {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 2rem;
    color: #161616;
    font-weight: 700;
    border-radius: 10rem;
    text-decoration: none;
    padding: 1.6rem 4.8rem;
    text-transform: uppercase;
    background-color: #fffc00;
    border: 2px solid transparent;
    font-family: "Clash Display", sans-serif;
    transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover {
        color: #fff;
        box-shadow: none;
        border-color: #fff;
        background-color: #161616;
    }
    &.mini-btn {
        font-size: 1.4rem;
        padding: 1rem 2rem 0.8rem 2rem;
    }
}

.outline-btn {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 2rem;
    color: #161616;
    font-weight: 700;
    border-radius: 10rem;
    text-decoration: none;
    padding: 1.6rem 4.8rem;
    text-transform: uppercase;
    border: 2px solid #161616;
    background-color: transparent;
    font-family: "Clash Display", sans-serif;
    transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover {
        color: #fff;
        box-shadow: none;
        background-color: #161616;
    }
    &.primary {
        color: #fff;
        border-color: #fffc00;
        &:hover {
            color: #161616;
            background-color: #ffd;
            box-shadow: 0 0 30px 0 #fffc00;
        }
    }
    &.mini-btn {
        font-size: 1.4rem;
        padding: 1rem 2rem 0.8rem 2rem;
    }
}

.tooltip {
    position: relative;
    .tooltip-trigger {
        cursor: pointer;
        display: inline;
        margin: 0 0 0 0.8rem;
    }
    .tooltip-content {
        left: 0;
        bottom: 0;
        width: 20rem;
        padding: 1rem;
        display: none;
        color: #ffffff;
        font-size: 1.4rem;
        border-radius: 4px;
        position: absolute;
        margin-left: -10rem;
        text-transform: none;
        background-color: #000000;
    }
    &:hover .tooltip-content {
        display: block;
    }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    width: 1rem;
    height: 1rem;
    color: #fffc00;
    position: relative;
    display: inline-block;
    border-radius: 0.6rem;
    background-color: #fffc00;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -1.4rem;
    width: 1rem;
    height: 1rem;
    color: #fffc00;
    border-radius: 0.6rem;
    background-color: #fffc00;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 1.4rem;
    width: 1rem;
    height: 1rem;
    color: #fffc00;
    border-radius: 0.6rem;
    background-color: #fffc00;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #fffc00;
    }
    50%,
    100% {
        background-color: #9e9d6a;
    }
}

/* The switch - the box around the slider */
.switch-label {
    width: 6rem;
    height: 3.6rem;
    position: relative;
    display: inline-block;
    .switch-input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .switch-slider {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        transition: 0.4s;
        position: absolute;
        -webkit-transition: 0.4s;
        background-color: #ccc;
        &:before {
            left: 4px;
            bottom: 4px;
            content: "";
            width: 2.8rem;
            height: 2.8rem;
            position: absolute;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    .switch-input:checked + .switch-slider {
        background-color: #fffc00;
    }

    .switch-input:focus + .switch-slider {
        box-shadow: 0 0 1px #fffc00;
    }
    .switch-input:checked + .switch-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded sliders */
    .switch-slider {
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: 992px) {
    .index-header {
        margin-top: 7.3rem;
    }
    .app-sidebar {
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 99;
        bottom: auto;
        height: auto;
    }
    .wallet-btn {
        padding: 1.6rem 2rem;
    }
    .leaderboard-table {
        padding: 1.6rem;
        margin: 2rem 0 0;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}
